*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

/* @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@1,500&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,700;1,500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,700;1,500&family=Montserrat:ital@1&display=swap');

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,700;1,500&family=Montserrat:wght@700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,700;1,500&family=Epilogue:wght@600&family=Inter&family=Montserrat:wght@700&family=Sora:wght@300&display=swap');

.pop,h2,h1,h3,b{
  font-family: 'Poppins', sans-serif;
}

.source,h2, h1,h3{
  /* font-family: 'Source Sans Pro', sans-serif; */
}

.text{
  /* font-family: 'DM Sans', sans-serif; */
  /* font-family: 'DM Sans', sans-serif; */
  /* font-family: 'DM Sans', sans-serif; */
  /* font-family: 'Montserrat', sans-serif; */
  /* font-family: 'Sora', sans-serif; */
  /* line-height: 40px; */
}

p,li{
  /* font-family: 'DM Sans', sans-serif; */
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Sora', sans-serif;
  /* font-family: 'Inter', sans-serif; */
  /* font-family: 'Epilogue', sans-serif; */
  color: #1e1e27;
  font-size: 20px;
}

h2{

}


.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.ebuIMG{
  /* width:70rem; */
}

/* @font-face {
  font-family: "Epilogue";
  font-family: "Inter Tight";
  src: url("./fonts/Epilogue/Epilogue-VariableFont_wght.ttf");
  src: url("./fonts/Inter_Tight/InterTight-VariableFont_wght.ttf")
  /* font-weight: bold;
  font-display: swap; 
} */

@media (min-width: 700px) {
  .ebuIMG{
    width:70rem;
  }
}